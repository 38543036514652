import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography, Button } from '@mui/material';
import { AnalyticEvent } from '../AnalyticsEvent';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const handleRedirect = (url, target = '_self', label) => {
  window.open(url, target);
  AnalyticEvent('Link', url, label);
};

const goToSupportPage = () => {
  handleRedirect('https://mxthega.me/kickkaps', '_blank', 'MtgKickstarter');
};

const goToMTGWebsite = () => {
  handleRedirect('https://www.motocrossthegame.com/', '_blank', 'MtgWebsite');
};

function BannerView() {
  return (
    <Box
      component="section"
      className="sectionTransparent"
      sx={{
        display: 'flex',
        overflow: 'hidden',
        minHeight: '500px',
        backgroundImage: 'url("/Images/MTG/mtg_compound.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container
        sx={{ mt: { xs: 10, md: 25 }, mb: { md: 40 }, display: 'flex', position: 'relative' }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Typography variant="h4" sx={{ mt: 5 }} textAlign="center">
                Scissors In The Plug is proudly working on:
              </Typography>
              <Box
                component="img"
                src="./Images/MTG/mtg_logo.png"
                alt="Virtual MX Logo"
                sx={{ height: 200, mb: 5 }}
                onClick={goToMTGWebsite}
              />
              <Typography variant="h4" sx={{ mb: 5 }} textAlign="center">
                In partnership with <span style={{ color: 'red' }}>Virtual MX</span>
              </Typography>
              <Box sx={{ mb: 40, display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Button
                  component="a"
                  color="primary"
                  variant="contained"
                  sx={{ fontSize: '1.5rem' }}
                  onClick={goToSupportPage}
                >
                  Support us
                </Button>
                <Button
                  component="a"
                  color="primary"
                  variant="contained"
                  sx={{ fontSize: '1.5rem' }}
                  onClick={goToMTGWebsite}
                >
                  Official website
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              mt: { xs: -30, md: 4 },
              mb: 8,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <iframe
              width="640"
              height="360"
              src="https://www.youtube.com/embed/WFhFSI5_zPo?si=_MbPEUiYM45-Stfp"
              title="Trailer VMX"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default BannerView;
