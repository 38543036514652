import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography, Button } from '@mui/material';
import { AnalyticEvent } from '../AnalyticsEvent';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const handleRedirect = (url, target = '_self') => {
  window.open(url, target);
  AnalyticEvent('Email', url, GAlabel);
  GAlabel = 'Mail Scissors';
};

function BriefView() {
  return (
    <Box
      component="section"
      className="sectionTransparent"
      sx={{ display: 'flex', overflow: 'hidden' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }} />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="./Images/vrLogo.png"
                alt="Scissors In The Plug Logo"
                sx={{ width: 300, height: 300 }}
              />
              <Typography variant="h4" sx={{ my: 5, height: 75 }} textAlign="center">
                Video game creation
              </Typography>
              <Typography variant="h5" textAlign="center">
                We create 2D and 3D video games, and also in augmented and virtual reality.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ height: 300 }}
              >
                <Grid item>
                  <Button
                    onClick={() =>
                      handleRedirect('https://kapsloc.com/', '_blank', 'KapslocWebsite')
                    }
                    sx={{ p: 0, width: 300, height: 'auto' }}
                  >
                    <Box
                      component="img"
                      src="./Images/Kapsloc.svg"
                      alt="Kapsloc Logo"
                      sx={{ width: 300 }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Typography variant="h4" sx={{ my: 5, height: 75 }} textAlign="center">
                Video game technologies applications for industry
              </Typography>
              <Typography variant="h5" textAlign="center">
                In association with Kapsloc, we develop advanced 3D solutions for architecture and
                industry.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="./Images/pluggyWorking.png"
                alt="Scissors In The Plug Logo"
                sx={{ width: 300, height: 300 }}
              />
              <Typography variant="h4" sx={{ my: 5, height: 75 }} textAlign="center">
                Need video game developers?
              </Typography>
              <Typography variant="h5" textAlign="center">
                Any game making assistance needed? A video game to brush up? We can help you
                complete your projects.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default BriefView;
