import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { AnalyticEvent } from '../AnalyticsEvent';

const handleGameRedirect = (e, redirectUrl, target = '_self') => {
  if (redirectUrl) {
    AnalyticEvent('GameLink', redirectUrl);
    window.open(redirectUrl, target);
  }
};

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 1,
    borderColor: theme.palette.common.white,
  },
  '&:hover .titleMarked': {
    opacity: 0,
    borderColor: theme.palette.common.white,
  },
}));

const TitleMarked = styled('div')(({ theme }) => ({
  height: 3,
  width: 18,
  background: theme.palette.common.white,
  position: 'relative',
  bottom: -20,
  left: 'calc(50% - 9px)',
  transition: 'all 0.3s ease',
  opacity: 1,
}));

const ImageMarked = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 'calc(50% - 20px)',
  left: 'calc(50% - 75px)',
  width: 150,
  height: 42,
  border: '2px solid transparent',
  borderWidth: 3,
  transition: 'all 0.3s ease',
  opacity: 0,
  pointerEvents: 'none',
}));

const images = [
  {
    url: './Images/MTG/mtg_bike.jpg',
    title: 'Motocross The Game',
    width: '55%',
    redirectUrl: 'https://store.steampowered.com/app/3188660/Motocross_The_Game/',
  },
  {
    url: './Images/Apeirophilia/Group_64.png',
    title: 'Apeirophilia',
    width: '45%',
    redirectUrl: 'https://store.steampowered.com/app/1789080/APEIROPHILIA/',
  },
  {
    url: './Images/Will/AfficheV01.gif',
    title: 'WILL.EXE',
    width: '40%',
    redirectUrl: 'https://store.steampowered.com/app/2504580/Willexe/',
  },
  {
    url: './Images/LaGrosseBertha/bertha_ecran_orga.png',
    title: 'La grosse bertha',
    width: '60%',
  },
  {
    url: './Images/BeTheMayor/BeTheMayorMenu.png',
    title: 'Be The Mayor',
    width: '50%',
    redirectUrl: 'https://arcweave.com/app/project/vVljr25lgq/play',
  },
  {
    url: './Images/ThirstSea/RENDER_TARGET.png',
    title: 'THIRST SEA',
    width: '50%',
  },
  {
    url: './Images/Beware/BewareMenu.png',
    title: 'Beware Of the Truth',
    width: '55%',
  },
  {
    url: './Images/LBF/pexels-thomas-k.jpg',
    title: 'Let the bills be free',
    width: '45%',
  },
  {
    url: './Images/Coura/render.png',
    title: 'Coura',
    width: '30%',
  },
  {
    url: './Images/BioPuzzle/BioPuzzleInGame.png',
    title: 'Biopuzzle',
    width: '70%',
  },
];

export default function ProjectsMasonry() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h3" marked="center" align="center" component="h2" gutterBottom>
        Our projects
      </Typography>
      <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
              cursor: image.redirectUrl ? 'pointer' : 'auto', // Définit le curseur en fonction de la présence d'un lien de redirection
            }}
            onClick={(e) => handleGameRedirect(e, image.redirectUrl, '_blank')}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 40%',
                backgroundImage: `url(${image.url})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            {image.redirectUrl && ( // Applique l'effet imageMarked uniquement si un lien est défini
              <ImageMarked className="imageMarked" />
            )}
            {image.redirectUrl && ( // Applique l'effet imageMarked uniquement si un lien est défini
              <TitleMarked className="titleMarked" />
            )}

            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography component="h3" variant="h6" color="inherit">
                {image.title}
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>
    </Container>
  );
}
