import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TeamCard from '../components/TeamCard';
import { Typography } from '@mui/material';

function TeamView() {
  return (
    <Box
      component="section"
      className="sectionTransparent"
      sx={{ display: 'flex', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" marked="center" align="center" component="h2">
          Our team
        </Typography>
        <br />
        <Grid
          container
          spacing={5}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '80vh' }}
        >
          <Grid item>
            <TeamCard
              name="Benjamin Coudannes"
              role="Founder & Developer"
              image="./Images/ben.png"
            />
          </Grid>
          <Grid item>
            <TeamCard
              name="Lucas Lequertier"
              role="Founder & 3D Artist"
              image="./Images/lucas_V3.png"
            />
          </Grid>
          <Grid item>
            <TeamCard
              name="Martin Darrigrand"
              role="Founder & 2D Artist"
              image="./Images/martin.png"
            />
          </Grid>
          <Grid item>
            <TeamCard
              name="Julien Milinkovitch"
              role="Founder & Developer"
              image="./Images/julien.png"
            />
          </Grid>
          <Grid item>
            <TeamCard name="David Gilardot" role="Founder" image="./Images/david_V4.png" />
          </Grid>
          <Grid item>
            <TeamCard name="Théo Calleja" role="Founder" image="./Images/theo.png" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default TeamView;
